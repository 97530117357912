export const PROVIDER_KEY = 'providerKey';
export const SERVICE_KEY = 'serviceId';
export const LATITUDE_KEY = 'latitude';
export const LONGITUDE_KEY = 'longitude';
export const CONTEXT_KEY = 'context';

export interface GarageSearch {
  [PROVIDER_KEY]: string,
  [SERVICE_KEY]: number,
  [LATITUDE_KEY]: number,
  [LONGITUDE_KEY]: number,
  [CONTEXT_KEY]: string
}

export function toHttpParams(dto: GarageSearch): Record<string, string> {
  return {
    [PROVIDER_KEY]: dto[PROVIDER_KEY],
    [SERVICE_KEY]: dto[SERVICE_KEY].toString(),
    [LATITUDE_KEY]: dto[LATITUDE_KEY].toString(),
    [LONGITUDE_KEY]: dto[LONGITUDE_KEY].toString(),
    [CONTEXT_KEY]: dto[CONTEXT_KEY],
  };
}
