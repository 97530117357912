import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Config, ConfigService} from "../../config";
import {CountrySettingsDto} from "../../dto/country-settings-dto";
import {GarageSearch, toHttpParams} from "../../../features/products/appointment/models/garage-search";
import {AppointmentInfo} from "../../../features/products/appointment/models/appointment-info";
import {NearestGarages} from "../../../features/products/appointment/models/nearest-garages";
import {Schedule} from "../../../features/products/appointment/models/time-slots";
import {
  CreateAppointmentDto,
  CreateAppointmentResponse
} from "../../../features/products/appointment/models/create-appointment-dto";
import {Utils} from "../../../shared/utils/generics.utils";
import {ProviderServices} from "../../../features/products/appointment/models/provider-services";
import {UiUtils} from "../../../shared/utils/ui.utils";

@Injectable({
  providedIn: 'root'
})
export class ExternalApiService {

  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  private config = this.configService.getConfig().rool

  setConfig(config: Config) {
    this.config = config.rool;
  }

  getCountrySettings(countryCode: string) {
    return this.http.get<CountrySettingsDto>(`${this.config.externalApi.url}/v1/countries/${countryCode}/settings`);
  }

  getAppointmentInfo(context: string) {
    return this.http.get<AppointmentInfo>(`${this.config.externalApi.url}/v1/appointments`, {
      params: new HttpParams({fromObject: {context}})
    });
  }

  getNearestGarages(dto: GarageSearch) {
    return this.http.get<NearestGarages>(`${this.config.externalApi.url}/v1/garages/nearest`, {
      params: new HttpParams({fromObject: toHttpParams(dto)})
    });
  }

  createAppointment(dto: CreateAppointmentDto) {
    return this.http.post<CreateAppointmentResponse>(`${this.config.externalApi.url}/v1/appointments`, dto);
  }

  getTimeSlots(garageId: number, serviceId: number, week: number) {
    return this.http.get<Schedule>(`${this.config.externalApi.url}/v1/garages/${garageId}/services/${serviceId}/timeslots`, {
      params: new HttpParams({fromObject: { week }})
    });
  }

  getProviderServices(context: string, provider: string = Utils.getCompanyFromDomain()) {
    return this.http.get<ProviderServices>(`${this.config.externalApi.url}/v1/providers/euromaster/services`, {
      params: new HttpParams({fromObject: { context, language: UiUtils.getBrowserLanguage() }})
    });
    // return this.http.get<ProviderServices>(`${this.config.externalApi.url}/v1/providers/${provider}/services`, {
    //   params: new HttpParams({fromObject: { context }})
    // });
  }
}
